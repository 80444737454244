'use client';
import React, { ReactNode } from 'react';
import type { AppProps } from 'next/app';
import Link from '@/components/link/Link';
import {
  ChakraProvider, Box, Portal, useDisclosure, useColorModeValue, Flex,
  List,
  ListItem,
  Text,
} from '@chakra-ui/react';
import Footer from '@/components/footer/FooterAdmin';
import { usePathname } from 'next/navigation';
import { useEffect, useState } from 'react';
import '@/styles/App.css';
import '@/styles/Contact.css';
import '@/styles/Plugins.css';
import '@/styles/MiniCalendar.css';
import AppWrappers from './AppWrappers';

export default function RootLayout({ children }: { children: ReactNode }) {
  const pathname = usePathname();
  const [apiKey, setApiKey] = useState('');
  const { isOpen, onOpen, onClose } = useDisclosure();
  useEffect(() => {
    const initialKey = localStorage.getItem('apiKey');
    console.log(initialKey);
    if (initialKey?.includes('sk-') && apiKey !== initialKey) {
      setApiKey(initialKey);
    }
  }, [apiKey]);

  const textColor = useColorModeValue('gray.100', 'white');

  return (
    <html lang="en">
      <body id={'root'}>
        <AppWrappers>
          {/* <ChakraProvider theme={theme}> */}
          {pathname?.includes('register') || pathname?.includes('sign-in') ? (
            children
          ) : (
            <Box>
              <Box
                pt={{ base: '60px', md: '10px' }}
                float="right"
                minHeight="100vh"
                height="100%"
                overflow="auto"
                position="relative"
                maxHeight="100%"
                w={{ base: '100%', xl: '100%' }}
                maxWidth={{ base: '100%', xl: '100%' }}
                transition="all 0.33s cubic-bezier(0.685, 0.0473, 0.346, 1)"
                transitionDuration=".2s, .2s, .35s"
                transitionProperty="top, bottom, width"
                transitionTimingFunction="linear, linear, ease"
                bgGradient="linear(to-b, #1A202C, #4B0082)"
              >
                <Box
                  mx="auto"
                  p={{ base: '20px', md: '30px' }}
                  pe="20px"
                  minH="85vh"
                  pt="50px"
                >
                  {children}
                  {/* <Component apiKeyApp={apiKey} {...pageProps} /> */}
                </Box>
                <Box>
                  <Flex
                    zIndex="3"
                    flexDirection={{
                      base: 'column',
                      xl: 'row',
                    }}
                    alignItems="center"
                    justifyContent="space-between"
                    px={{ base: '30px', md: '50px' }}
                    pb="30px"
                  >
                    <Text
                      color={textColor}
                      fontSize={{ base: 'xs', md: 'sm' }}
                      textAlign={{
                        base: 'center',
                        xl: 'start',
                      }}
                      fontWeight="500"
                      mb={{ base: '10px', xl: '0px' }}
                    >
                      {' '}
                      &copy; {new Date().getFullYear()}
                      <Text as="span" fontWeight="500" ms="4px">
                        Cloudnitio. All Rights Reserved.
                      </Text>
                    </Text>
                    <List display="flex">
                      <ListItem
                        me={{
                          base: '10px',
                          md: '44px',
                        }}
                      >
                        <Link
                          fontWeight="500"
                          fontSize={{ base: 'xs', md: 'sm' }}
                          color={textColor}
                          href="#"
                        >
                          Terms of Use
                        </Link>
                      </ListItem>
                      <ListItem>
                        <Link
                          fontWeight="500"
                          fontSize={{ base: 'xs', md: 'sm' }}
                          color={textColor}
                          href="#"
                        >
                          Privacy Policy
                        </Link>
                      </ListItem>
                    </List>
                  </Flex>
                </Box>
              </Box>
            </Box>
          )}
          {/* </ChakraProvider> */}
        </AppWrappers>
      </body>
    </html>
  );
}
