'use client';
// Chakra imports
import { Flex, useColorModeValue } from '@chakra-ui/react';
import Image from 'next/image';

import { HorizonLogo } from '@/components/icons/Icons';
import { HSeparator } from '@/components/separator/Separator';
import RaiaLogoPng from "../../../../public/img/raia/Raia-Logo-Web.png"

export function SidebarBrand() {
  //   Chakra color mode
  let logoColor = useColorModeValue('navy.700', 'white');

  return (
    <Flex alignItems="center" flexDirection="column">
      <Image
      src={RaiaLogoPng}
      alt="Optimized image"
      width={150}
      height={50}
    />
      {/* <HorizonLogo h="26px" w="146px" my="30px" color={logoColor} /> */}
      <HSeparator mb="20px" w="284px" mt="20px" />
    </Flex>
  );
}

export default SidebarBrand;
